@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
  footer
========================================================================== */
footer {
  position: relative;
  background-color: g.$col-blue;

  @include g.mq(md) {
    padding-bottom: 40px;
  }

  @include g.mq(sm) {
    padding-bottom: f.px2vw(60);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;

    @include g.mq(md) {
      background-image: url("../img/common/footer-bg01.png");
      background-size: cover;
      background-position-x: center;
      background-repeat: no-repeat;  
      top: -23px;
      left: 0;
      height: 54px;
    }

    @include g.mq(sm) {
      background-image: url("../img/common/sp/footer-bg01.png");
      background-size: cover;
      background-repeat: no-repeat;    
      top: f.px2vw(-38);
      left: 0;
      height: f.px2vw(49);
    }
  }

  .footer-wrap {
    max-width: 1000px;
    margin: 0 auto;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      padding: 37px 0 20px;
    }

    &__logo {

      @include g.mq(md) {
        flex: 0 0 262px;
        margin-right: 82px;
      }

      @include g.mq(sm) {
        margin: 0 auto;
        padding-top: f.px2vw(21);
      }

      .logo-img {
        margin: 0 auto;

        @include g.mq(md) {
          width: 170px;
        }

        @include g.mq(sm) {
          width: f.px2vw(154);
        }
      }

      .address-txt {
        font-weight: 300;
        color: #fff;

        @include g.mq(md) {
          font-size: 1.3rem;
          margin-top: 24px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(14);
          text-align: center;
          margin-top: f.px2vw(15);
        }
      }
    }

    &__box {

      @include g.mq(md) {
        display: flex;
        flex: 0 1 100%;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(31);
        padding: 0 f.px2vw(67);
      }

      .box-list {

        @include g.mq(md) {
          padding: 0 40px;
          border-left: 1px solid rgba(255,255,255,.5);
        }

        .list-item {
          font-weight: 300;

          @include g.mq(md) {
            font-size: 1.6rem;
            margin-top: 37px;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(16);
            letter-spacing: 0.1em;
            text-align: center;
            border-bottom: 1px solid rgba(255,255,255,.5);
          }

          &:first-child {
            margin-top: 0;
          }

          a {
            color: #fff;

            @include g.mq(sm) {
              display: block;
              padding: f.px2vw(31) 0;
            }

            &.tab {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background-image: url("../img/common/tab-icon01.png");
                background-size: cover;
                background-repeat: no-repeat;

                @include g.mq(md) {
                  right: -20px;
                  width: 17px;
                  height: 14px;
                }

                @include g.mq(sm) {
                  right: f.px2vw(8);
                  width: f.px2vw(13);
                  height: f.px2vw(11);
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    font-weight: 300;
    text-align: center;
    color: #fff;

    @include g.mq(md) {
      font-size: 1.2rem;
      margin-top: 43px;
    }

    @include g.mq(sm) {
      font-size: f.px2vw(10);
      text-align: center;
      margin-top: f.px2vw(58);
    }
  }
}