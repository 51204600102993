@use "../global" as g;
@use 'function' as f;

/* ==========================================================================
    base
========================================================================== */

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  color: g.$font-color;
  background-color: #fff;
  font-family: g.$font-kiwi;
  font-weight: 300;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  @include g.mq(md) {
    font-size: 1.6rem;
  };

  @include g.mq(sm) {
    font-size: f.px2vw(14);
  }
}

a,
button {
  color: inherit;
  cursor: pointer;
  opacity: 1;
  transition: all .3s;

  &:hover {
    opacity: .5;
  }
}

img {
  max-width: 100%;
  width: 100%;
}

em {
  font-style: normal;
}

button {
  outline: none;
  border: 0;
  background-color: transparent;
}