/*==============================================================
# Animation
==============================================================*/
@keyframes scroll-bar {
  0% {
    bottom: 83px;
  }
  
  100% {
    bottom: 0;
  }
}

@keyframes shake-icon01 {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes shake-icon02 {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}