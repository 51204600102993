@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# ⼊園案内
==============================================================*/
#recruit {

  .cmn-title {
    @include g.mq(md) {
      font-size: 3.8rem;
      line-height: 1.47;
    }
    @include g.mq(sm) {
      line-height: 1.43;
    }
  }

  .recruit-wrap {

    .about {
      position: relative;
      overflow-x: clip;

      @include g.mq(md) {
        margin-top: 59px;
        padding-bottom: 119px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px 119px;
      }

      @include g.mq(sm) {
        padding: 0 f.px2vw(20) f.px2vw(102);
        margin-top: f.px2vw(35);
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .img {

          @include g.mq(md) {
            margin-top: 57px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(49);
          }
        }

        .txt {
          font-weight: 200;

          @include g.mq(md) {
            font-size: 1.6rem;
            line-height: 2.13;
            padding: 0 60px;
            margin-top: 30px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(14);
            line-height: 1.57;
            padding: 0 f.px2vw(20);
            margin-top: f.px2vw(28);
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          display: none;
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 178px;
              right: 68px;
              width: 98px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-53);
              width: f.px2vw(50);
              right: f.px2vw(5);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 366px;
              left: 48px;
              width: 58px;
            }
            @include g.mq(sm) {
              top: f.px2vw(1006);
              left: f.px2vw(40);
              width: f.px2vw(50);
            }
          }

          &-03 {

            @include g.mq(md) {
              top: 680px;
              left: -40px;
              width: 72px;
            }
            @include g.mq(sm) {
              display: none;
            }
          }
        }
      }
    }

    .point {
      position: relative;
      overflow-x: clip;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 64px 0 100px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 64px 20px 120px;
      }

      @include g.mq(sm) {
        padding: f.px2vw(37) f.px2vw(40) f.px2vw(130);
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-list {
          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            margin-top: 57px;
          }
  
          @include g.mq(sm) {
            margin-top: f.px2vw(30);
          }
  
          .item {
            @include g.mq(md) {
              display: flex;
              flex-direction: column;
              width: 30%;
            }
            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(60);
            }
            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }
            .title {
              font-weight: 500;
              text-align: center;
              color: g.$col-blue;
  
              @include g.mq(md) {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 77px;
                font-size: 2rem;
                line-height: 1.3;
                margin: 21px 0 13px;
              }
              @media screen and (max-width:1050px) and (min-width: 767px){
                margin: 30px 0 20px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(20);
                line-height: 1.3;
                margin-top: f.px2vw(11);
              }
            }
  
            .txt {
              @include g.mq(md) {
                line-height: 1.75;
                margin-top: auto;
              }
              @include g.mq(sm) {
                line-height: 1.57;
                margin-top: f.px2vw(16);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon01.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: 554px;
            left: 3px;
            width: 226px;
            height: 226px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -250px;
            right: 33px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: -44px;
              left: 353px;
              width: 64px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-63);
              left: f.px2vw(47);
              width: f.px2vw(57);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: -36px;
              right: 254px;
              width: 128px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-58);
              right: f.px2vw(20);
              width: f.px2vw(84);
            }
          }
        }
      }
    }

    .button-list {
      max-width: 300px;
      margin: 0 auto;

      @include g.mq(md) {
        margin: 50px auto 137px;
      }
      @include g.mq(sm) {
        max-width: f.px2vw(249);
        margin: f.px2vw(12) auto f.px2vw(141);
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        border: 1px solid g.$col-blue;

        @include g.mq(md) {
          height: 60px;
          margin-top: 39px;
          border-radius: 41px;
          font-size: 16px;
        }
        @include g.mq(sm) {
          height: f.px2vw(49);
          border-radius: f.px2vw(25);
          margin-top: f.px2vw(20);
          font-size: f.px2vw(16);
          letter-spacing: -.05em;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;

          @include g.mq(md) {
            right: 20px;
            border-width: 4px 0 4px 7px;
          }
          @include g.mq(sm) {
            right: 17px;
            border-width: f.px2vw(4) 0 f.px2vw(4) f.px2vw(7);
          }
        }

        &.button-link {
          color: #fff;
          background-color: g.$col-blue;

          &::after {
            border-color: transparent transparent transparent #fff;
          }
        }
        &.contact-link {
          color: g.$col-blue;
          background-color: #fff;

          &::after {
            border-color: transparent transparent transparent g.$col-blue;
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}