@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
header
========================================================================== */
header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all .3s;

  @include g.mq(md) {
    overflow-x: clip;
    height: 125px;
  }

  @include g.mq(sm) {
    height: f.px2vw(70);
  }

  &::after {
    transition: all .3s;
  }
  
  .header-wrap {
    position: relative;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      z-index: -1;

      @include g.mq(md) {
        top: -85px;
        width: 462px;
        height: 297px;
        border-radius: 51%;
      }

      @include g.mq(sm) {
        top: f.px2vw(4);
        width: f.px2vw(236);
        height: f.px2vw(113);  
        border-radius: 50%;
      }
    }

    .header-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;

      @include g.mq(md) {
        top: 29px;
        width: 219px;
      }

      @include g.mq(sm) {
        width: f.px2vw(117);
        top: f.px2vw(16);
      }
    }

    &__pc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1000px;
      height: 100%;
      margin: 0 auto;
      background-color: #fff;

      @include g.mq(tab_sm) {
        display: none;
      }

      .header-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 370px;
        width: 100%;

        .list-item {

          @include g.mq(md) {
            width: 26%;
          }

          &__link {
            display: block;
            text-align: center;

            .img {
              
              @include g.mq(md) {
                height: 28px;
              }

              img {
                object-fit: contain;
                height: 100%;
              }
            }
    
            p {
              font-weight: 300;
    
              @include g.mq(md) {
                font-size: 1.8rem;
                margin-top: 5px;
              }

            }
          }
        }
      }
    }

    .contact {
      position: absolute;
      transition: all .3s;
      cursor: pointer;
  
      @include g.mq(md) {
        top: -41px;
        right: -28px;
      }
  
      @include g.mq(tab_sm) {
        display: none;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: g.$col-orange;
        border-radius: 50%;
        
        @include g.mq(md) {
          width: 180px;
          height: 180px;  
        }

        &:hover {
          opacity: .8;
  
          @include g.mq(md) {
            width: 210px;
            height: 210px;
          }
        }

        .contact-img {
        
          @include g.mq(md) {
            width: 27px;
            margin-top: 20px;
          }
        }
    
        .contact-txt {
          font-weight: 300;
          color: #fff;
    
          @include g.mq(md) {
            font-size: 1.8rem;
            margin-top: 5px;
          }
        }
      }
    }

    &__sp {
      background-color: #fff;
      height: 100%;

      @include g.mq(tab_md) {
        display: none;
      }

      .header-icon {
        position: absolute;
        z-index: 999;

        @include g.mq(tab_sm) {
          top: 46px;
          right: 60px;
          width: 38px;
        }

        @include g.mq(sm) {
          top: f.px2vw(27);
          right: f.px2vw(20);
          width: f.px2vw(38);  
        }
    
        span {
          display: inline-block;
          position: absolute;
          left: 0;
          width: 100%;
          height: f.px2vw(3);
          transition: all .3s;
          background: g.$col-orange;

          @include g.mq(tab_sm) {
            height: 3px;
          }
    
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: f.px2vw(7);
            @include g.mq(tab_sm) {
              top: 7px;
            }
          }
          &:nth-of-type(3) {
            top: f.px2vw(14);
            @include g.mq(tab_sm) {
              top: 14px;
            }
          }
        }
    
        &.active {
          opacity: 1;
    
          span {
            width: f.px2vw(30);

            @include g.mq(tab_sm) {
              width: 30px;
            }

            @include g.mq(sm) {
              width: f.px2vw(30);
            }
    
            &:nth-of-type(1) {
              transform:rotate(-45deg);

              @include g.mq(tab_sm) {
                top: 7px;
                left: 4px;
              }
              @include g.mq(sm) {
                top: f.px2vw(7);
                left: f.px2vw(4);  
              }
            }
            &:nth-of-type(2) {
              opacity: 0;
            }
            &:nth-of-type(3) {
              transform: rotate(45deg);
              @include g.mq(tab_sm) {
                top: 7px;
                left: 4px;
              }
              @include g.mq(sm) {
                top: f.px2vw(7);
                left: f.px2vw(4);  
              }
            }
          }
        }
      }

      .header-humberger {
        display: none;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: -1;
    
        &__list {
          height: 100%;
          text-align: center;
          overflow-y: scroll;

          @include g.mq(tab_sm) {
            padding: 215px 67px 121px;
          }

          @include g.mq(sm) {
            padding: f.px2vw(126) f.px2vw(67) f.px2vw(121);
          }
    
          .list-item {
            border-bottom: 1px solid #E5E5E5;
    
            &__link {
              display: block;
              font-weight: 300;
              text-align: center;
              letter-spacing: 0.1em;

              @include g.mq(tab_sm) {
                font-size: 1.8rem;
                padding: 30px 0;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(18);
                padding: f.px2vw(30) 0;
              }
            }
    
            &:last-child {
              border-bottom: none;
            }
          }

          .contact-item  {
            a {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: g.$col-orange;

              @include g.mq(tab_sm) {
                width: 240px;
                height: 59px;
                border-radius: 41px;
                margin-top: 30px;
              }

              @include g.mq(sm) {
                width: f.px2vw(240);
                height: f.px2vw(59);
                border-radius: f.px2vw(41);
                margin-top: f.px2vw(30);  
              }
      
              p {
                position: relative;
                font-weight: 500;
                color: #fff;

                @include g.mq(tab_sm) {
                  padding-left: 35px;
                  font-size: 1.8rem;
                }

                @include g.mq(sm) {
                  padding-left: f.px2vw(35);
                  font-size: f.px2vw(18);  
                }
                
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  background-image: url("../img/common/contact-icon.png");
                  background-size: cover;
                  background-repeat: no-repeat;

                  @include g.mq(tab_sm) {
                    width: 25px;
                    height: 18px;  
                  }

                  @include g.mq(sm) {
                    width: f.px2vw(25);
                    height: f.px2vw(18);  
                  }
                }
              }
            }
          }
        }

      }
    }
  }

  &.active {
    top: -215px;
  }

  &.shadow {
    box-shadow: 0 3px 15px rgba(0,0,0,.1);

    &::after {
      @include g.mq(sm) {
        box-shadow: 0 f.px2vw(3) f.px2vw(15) rgba(0,0,0,.1);
      }
    }

    .header-wrap {

      &::after {
        box-shadow: 0 3px 15px rgba(0,0,0,.1);
      }
    }
  }
}