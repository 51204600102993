@use "../global" as g;
@use 'function' as f;
/*==============================================================
# 共通
==============================================================*/
.cmn-title {
  position: relative;
  font-weight: 500;
  text-align: center;
  color: g.$col-blue;

  @include g.mq(md) {
    padding-bottom: 25px;
    font-size: 3.4rem;
  }

  @include g.mq(sm) {
    font-size: f.px2vw(28);
    letter-spacing: -0.05em;
    padding-bottom: f.px2vw(28);
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;

    @include g.mq(md) {
      background-image: url("../img/common/txt-bg01.png");
      background-size: cover;
      background-repeat: no-repeat;  
      width: 111px;
      height: 10px;
    }

    @include g.mq(sm) {
      background-image: url("../img/common/sp/txt-bg01.png");
      background-size: cover;
      background-repeat: no-repeat;  
      width: f.px2vw(103);
      height: f.px2vw(10);
    }
  }
}

.cmn-txt {
  font-weight: 200;
  line-height: 2;
  text-align: center;

  @include g.mq(md) {
    font-size: 1.6rem;
  }

  @include g.mq(sm) {
    font-size: f.px2vw(14);
  }
}

/*==============================================================
# 下層
==============================================================*/
.under-box {
  position: relative;
  width: 100%;

  @include g.mq(md) {
    background-image: url("../img/common/under-bg01.png");
    background-size: cover;
    background-repeat: repeat;  
    background-position-y: bottom;
    height: 278px;
  }

  @include g.mq(sm) {
    background-image: url("../img/common/sp/under-bg01.png");
    background-size: cover;
    background-repeat: repeat;  
    width: 90%;
    height: f.px2vw(210);
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: url("../img/common/bg-icon01.png");
    background-size: cover;
    background-repeat: no-repeat;

    @include g.mq(md) {
      bottom: -85px;
      left: 0;
      width: 226px;
      height: 226px;
    }
  }

  .under-title {
    font-weight: 500;
    text-align: center;

    @include g.mq(md) {
      font-size: 4rem;
      padding-top: 135px;
    }

    @include g.mq(sm) {
      font-size: f.px2vw(28);
      padding-top: f.px2vw(86);
    }
  }
}

canvas {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
}

/*==============================================================
# fadeIn
==============================================================*/
.fadeIn_up {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
.fadeIn_up_repeat {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up_repeat.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
