@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# お知らせ
==============================================================*/
#news {

  .news-wrap {
    max-width: 1000px;

    @include g.mq(md) {
      margin: 96px auto 242px;
    }

    @media screen and (min-width: 767px) and (max-width: 1100px){
      padding: 0 20px;
    }

    @include g.mq(sm) {
      padding: 0 f.px2vw(60);
      margin-bottom: f.px2vw(95);
    }

    &__title {
      font-weight: 500;
      text-align: center;
      color: g.$col-blue;

      @include g.mq(md) {
        font-size: 4.8rem;
      }
      @include g.mq(sm) {
        font-size: f.px2vw(28);
        letter-spacing: -0.05em;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include g.mq(md) {
        margin-top: 90px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(37);
      }


      &::before{
        content:"";
        display: block;
        width:23.4%;
        order:1;
      }
      &::after{
        content:"";
        display: block;
        width:23.4%;
      }

      .list-item {

        @include g.mq(md) {
          width: 23.4%;
          margin-bottom: 104px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(30);
        }

        .img {
          
          @include g.mq(md) {
            height: 158px;
          }

          @include g.mq(sm) {
            height: f.px2vw(172);
          }
        }

        img {
          height: 100%;
          object-fit: cover;

          @include g.mq(md) {
            border-radius: 10px;
          }

          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }
        }

        &__link {

          .message {

            .date {
              display: flex;
              align-items: center;
  
              @include g.mq(md) {
                font-size: 1.3rem;
                margin-top: 15px;
              }
  
              @include g.mq(sm) {
                font-size: f.px2vw(13);
                margin-top: f.px2vw(15);
              }
  
              span {
                background-color: g.$col-orange;
                color: #fff;
  
                @include g.mq(md) {
                  font-size: 1.2rem;
                  padding: 4px 10px;
                  margin-left: 12px;
                }
  
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  letter-spacing: 0.049;
                  padding: f.px2vw(4) f.px2vw(11);
                  margin-left: f.px2vw(15);
                }
              }
            }
  
            .txt {
  
              @include g.mq(md) {
                font-size: 1.6rem;
                margin-top: 8px;
              }
  
              @include g.mq(sm) {
                font-size: f.px2vw(16);
                margin-top: f.px2vw(8);
              }
            }
          }
        }

        &:first-child {
          @include g.mq(sm) {
            margin-top: 0;
          }
        }
      }
    }

    &__page {
      text-align: center;

      @include g.mq(md) {
        margin-top: 27px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(50);
      }

      .page-list {
        position: relative;
        display: inline-flex;
        justify-content: center;  


        .list-item {
          border: 1px solid g.$col-blue;
          border-radius: 50%;
          background-color: #fff;

          @include g.mq(md) {
            width: 40px;
            height: 40px;
            margin: 0 15px;
          }
          @include g.mq(sm) {
            width: f.px2vw(30);
            height: f.px2vw(30);
            margin: 0 f.px2vw(10);
          }

          &__link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-weight: 500;
            color: g.$col-blue;
  
            @include g.mq(md) {
              font-size: 2rem;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(15);
            }
          }

          &.active {
            background-color: rgba($color: g.$col-blue, $alpha: 0.1);
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @include g.mq(md) {
            width: 31px;
          }
          @include g.mq(sm) {
            width: f.px2vw(25);
          }

          &-prev {

            @include g.mq(md) {
              left: -44px;
            }
            @include g.mq(sm) {
              left: f.px2vw(-40);
            }

            img {
              transform: rotate(180deg);
            }
          }

          &-next {

            @include g.mq(md) {
              right: -44px;
            }
            @include g.mq(sm) {
              right: f.px2vw(-40);
            }
          }

          &.no-active {
            opacity: .5;
            pointer-events: none;
          }
        }
      }
    }
  }
}

/*==============================================================
# 詳細ページ
==============================================================*/
#news-single {

  .single-wrap {
    max-width: 880px;

    @include g.mq(md) {
      margin: 80px auto 180px;
    }
    @media screen and (min-width: 767px) and (max-width: 1000px){
      padding: 0 20px;
    }

    @include g.mq(sm) {
      margin: f.px2vw(30) 0 f.px2vw(142);
      padding: 0 f.px2vw(40);
    }

    img {
      width: auto;
    }

    &__date {
      display: flex;
      align-items: center;

      @include g.mq(md) {
        font-size: 1.3rem;
      }

      @include g.mq(sm) {
        font-size: f.px2vw(13);
      }

      span {
        background-color: g.$col-orange;
        color: #fff;

        @include g.mq(md) {
          font-size: 1.2rem;
          padding: 4px 10px;
          margin-left: 12px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(12);
          letter-spacing: 0.049;
          padding: f.px2vw(4) f.px2vw(11);
          margin-left: f.px2vw(15);
        }
      }
    }

    &__title {
      font-weight: 500;
      color: g.$col-blue;
      line-height: 1.5;
      border-bottom: 1px solid g.$col-blue;

      @include g.mq(md) {
        font-size: 3.6rem;
        margin-top: -6px;
        padding-bottom: 12px;
      }
      @include g.mq(sm) {
        font-size: f.px2vw(18);
        margin-top: f.px2vw(12);
        padding-bottom: f.px2vw(10);
      }
    }

    &__img {

      @include g.mq(md) {
        margin-top: 19px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(27);
      }

      img {
        height: auto;

        @include g.mq(md) {
          border-radius: 20px;
        }
        @include g.mq(sm) {
          border-radius: f.px2vw(20);
        }
      }
    }

    &__content {

      @include g.mq(md) {
        margin-top: 19px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(20);
      }

      p {
        font-weight: 300;

        @include g.mq(md) {
          font-size: 1.6rem;
          line-height: 1.75;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(13);
          line-height: 1.85;
        }
      }

      img {
        margin: 10px 0;
      }
    }

    &__button {
      text-align: center;

      @include g.mq(md) {
        margin-top: 60px;
      }
      @include g.mq(sm) {
        margin-top: f.px2vw(24);
      }

      .button-link {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        background-color: g.$col-blue;
        color: #fff;

        @include g.mq(md) {
          width: 300px;
          height: 60px;
          border-radius: 41px;
          font-size: 1.6rem;
        }
        @include g.mq(sm) {
          width: f.px2vw(227);
          height: f.px2vw(49);
          border-radius: f.px2vw(25);
          font-size: f.px2vw(16);
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent #fff;

          @include g.mq(md) {
            right: 19px;
            border-width: 5px 0 5px 8px;
          }
          @include g.mq(sm) {
            right: f.px2vw(17);
            border-width: f.px2vw(4) 0 f.px2vw(4) f.px2vw(7);
          }
        }
      }
    }
  }
}
