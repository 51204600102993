@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
#　園について
==============================================================*/
#about {

  .about-wrap {

    @include g.mq(md) {
      margin-top: 59px;
    }

    @include g.mq(sm) {
      margin-top: f.px2vw(15);
    }

    &__message {
      position: relative;
      overflow: hidden;

      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }
      @include g.mq(tab1020) {
        padding: 0 20px;
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
  
        h2 {
          line-height: 1.47;
  
          @include g.mq(md) {
            font-size: 3.8rem;
            letter-spacing: 0;
            padding-bottom: 35px;
          }
          @include g.mq(sm) {
            padding-bottom: f.px2vw(37);
          }
        }
  
        .img {
  
          @include g.mq(md) {
            margin-top: 36px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(35);
          }
        }
  
        .txt {
          font-weight: 300;
  
          @include g.mq(md) {
            font-size: 1.6rem;
            line-height: 2.125;
            margin-top: 30px;
            padding: 0 60px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(14);
            line-height: 2;
            margin-top: f.px2vw(25);
            padding: 0 f.px2vw(20);
          }
  
          span {
            display: block;
            text-align: right;
  
            @include g.mq(md) {
              margin-top: 5px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(21);
            }
          }
        }
      }

      .bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
          top: 0;
        }

        @include g.mq(sm) {
          display: none;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: 527px;
            right: -36px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            top: f.px2vw(705);
            right: f.px2vw(-27);    
            width: f.px2vw(151);
            height: f.px2vw(139);
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 189px;
              right: 68px;
              width: 98px;
            }
          }

          &-02 {
            @include g.mq(md) {
              top: 516px;
              left: 53px;
              width: 71px;
            }
          }
        }
      }
    }

    &__idea {
      position: relative;

      @include g.mq(md) {
        padding-bottom: 200px;
      }

      @include g.mq(tab1020) {
        padding: 0 20px 200px;
      }

      @include g.mq(sm) {
        padding-bottom: f.px2vw(157);
      }

      .content {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        background-color: g.$col-lightblue;  

        @include g.mq(md) {
          margin-top: 52px;
          padding: 20px;
          border-radius: 14px;
        }
        @include g.mq(sm) {
          width: 90%;
          margin: f.px2vw(59) auto 0;
          padding: f.px2vw(20);
          border-radius: f.px2vw(14);
        }
  
        &::before,&::after {
          content: "";
          position: absolute;
          background-size: cover;
          background-repeat: no-repeat;
        }
  
        &::before {
          background-image: url("../img/common/bg-icon03.png");
          
          @include g.mq(md) {
            top: 5px;
            left: 183px;
            width: 64px;
            height: 55px;
          }
          @include g.mq(sm) {
            top: f.px2vw(-3);
            left: f.px2vw(28);
            width: f.px2vw(57);
            height: f.px2vw(48);
          }
        }
  
        &::after {
          background-image: url("../img/common/bg-icon06.png");
  
          @include g.mq(md) {
            top: 52px;
            right: 78px;
            width: 128px;
            height: 143px;
          }
          @include g.mq(sm) {
            top: 0;
            right: 0;
            width: f.px2vw(84);
            height: f.px2vw(95);
          }
        }

        .box {
          background-color: #fff;
  
          @include g.mq(md) {
            padding: 60px 0 54px;
          }
          @include g.mq(sm) {
            padding: f.px2vw(35) f.px2vw(15) f.px2vw(24);
          }
  
          .txt {
            font-weight: 300;
            text-align: center;
  
            @include g.mq(md) {
              font-size: 1.6rem;
              line-height: 2.125;
              margin-top: 25px;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(14);
              line-height: 2;
              margin-top: f.px2vw(21);
            }
          }
        }
      }
    }

    &__summary {
      position: relative;
      overflow-x: clip;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 64px 0 185px;
      }

      @include g.mq(tab1020) {
        padding: 0 20px;
      }

      @include g.mq(sm) {
        padding: 0;
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;

        h2 {

          @include g.mq(sm) {
            font-size: f.px2vw(34);
          }
        }

        .box {

          @include g.mq(md) {
            display: flex;
            margin-top: 48px;
          }
          @include g.mq(sm) {
            padding: 0 f.px2vw(40);
          }

          .list {

            @include g.mq(md) {
              flex: 0 1 100%;
              margin-top: 4px;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(40);
            }

            dl {
              display: flex;
              align-items: baseline;
              border-bottom: 1px solid g.$col-blue;

              @include g.mq(md) {
                padding: 15px 0;
              }
              @include g.mq(sm) {
                padding: f.px2vw(15) 0;
              }

              &:last-child {
                border-bottom: none;
              }

              dt {
                font-weight: 500;
                color: g.$col-blue;

                @include g.mq(md) {
                  flex: 0 0 18%;
                  line-height: 1.75;
                  font-size: 1.6rem;
                }

                @include g.mq(sm) {
                  flex: 0 0 32%;
                  font-size: f.px2vw(14);
                }
              }

              dd {
                font-weight: 300;

                @include g.mq(md) {
                  font-size: 1.6rem;
                  line-height: 1.75;
                  padding-left: 20px;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(14);
                  line-height: 1.57;
                }
                span {
                  @include g.mq(md) {
                    display: inline-block;
                  }
                }
              }

              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                @include g.mq(sm) {
                  border-bottom: 0;
                }
              }
            }
          }

          .img {

            @include g.mq(md) {
              flex: 0 0 433px;
              margin-left: 87px;
            }

            @include g.mq(tab1020) {
              flex: 0 0 f.px2vw(433,1020);
              margin-left: f.px2vw(87,1020);
            }

            @include g.mq(sm) {
              margin: f.px2vw(13) 0 0 ;
            }
          }
        }
      }

      .bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
          top: 0;
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
          top: 0;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon01.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -179px;
            left: -33px;
            width: 226px;
            height: 226px;
          }

          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 27px;
              right: 20px;
              width: 219px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-31);
              right: f.px2vw(20);
              width: f.px2vw(67);
            }
          }
        }
      }
    }

    &__accsess {
      position: relative;
      overflow: hidden;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 40px 0 116px;
      }
      @include g.mq(tab1020) {
        padding: 40px 20px 116px;
      }
      @include g.mq(sm) {
        padding: f.px2vw(60) f.px2vw(20) f.px2vw(71);
      }

      .content {
        position: relative;
        z-index: 2;
        max-width: 1000px;
        margin: 0 auto;

        h2 {
          @include g.mq(sm) {
            font-size: f.px2vw(34);
          }
        }

        .txt {
          font-weight: 300;
          text-align: center;

          @include g.mq(md) {
            font-size: 1.6rem;
            line-height: 1.875;
            margin-top: 40px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(14);
            line-height: 1.57;
            margin-top: f.px2vw(32);
          }
        }

        .map {

          @include g.mq(md) {
            height: 368px;
            margin-top: 39px;
          }
          @include g.mq(sm) {
            height: f.px2vw(295);
            padding: 0 f.px2vw(20);
            margin-top: f.px2vw(31);
          }

          iframe {

            @include g.mq(md) {
              border-radius: 16px;
            }
            @include g.mq(sm) {
              border-radius: f.px2vw(16);
            }
          }
        }
      }

      .bg {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 142px;
              left: 89px;
              width: 115px;
            }
            @include g.mq(sm) {
              top: f.px2vw(55);
              left: f.px2vw(19);
              width: f.px2vw(72);
            }
          }
          &-02 {

            @include g.mq(md) {
              bottom: 59px;
              right: 75px;
              width: 60px;
            }
            @include g.mq(sm) {
              display: none;
            }
          }
        }
      }
    }

    &__info {
      .content {
        width: 100%;

        @include g.mq(md) {
          background-image: url("../img/about/about-bg01.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position-x: center;  
          height: 366px;
          padding-top: 89px;
        }
        @include g.mq(sm) {
          background-image: url("../img/about/about-bg01.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position-x: center;
          height: f.px2vw(366);
          padding-top: f.px2vw(75);
        }

        .title {
          position: relative;
          font-weight: 500;
          text-align: center;
          color: #fff;

          @include g.mq(md) {
            font-size: 3.4rem;
            padding-bottom: 24px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(28);
            padding-bottom: f.px2vw(30);
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-image: url("../img/common/txt-bg02.png");
            background-size: cover;
            background-repeat: no-repeat;

            @include g.mq(md) {
              width: 111px;
              height: 10px;
            }
            @include g.mq(sm) {
              width: f.px2vw(103);
              height: f.px2vw(10);
            }
          }
        }

        .txt {
          font-weight: 300;
          text-align: center;
          color: #fff;

          @include g.mq(md) {
            font-size: 1.6rem;
            margin-top: 30px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(14);
            line-height: 1.57;
            margin-top: f.px2vw(26);
          }
        }

        .button {
          text-align: center;

          @include g.mq(md) {
            margin-top: 44px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(19);
          }

          .button-link {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            font-weight: 500;
            color: #fff;

            @include g.mq(md) {
              width: 218px;
              height: 40px;
              border-radius: 41px;
              font-size: 1.6rem;
            }
            @include g.mq(sm) {
              width: f.px2vw(218);
              height: f.px2vw(40);
              border-radius: f.px2vw(41);
              font-size: f.px2vw(16);
            }

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-color: transparent transparent transparent #fff;

              @include g.mq(md) {
                right: 19px;
                border-width: 5px 0 5px 8px;
              }
              @include g.mq(sm) {
                right: f.px2vw(19);
                border-width: f.px2vw(5) 0 f.px2vw(5) f.px2vw(8);
              }
            }
          }
        }
      }
    }
  }
}