@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# 園の特色
==============================================================*/
#flow {

  @include g.mq(md) {
    margin-bottom: 135px;
  }
  @include g.mq(sm) {
    margin-bottom: f.px2vw(119);
  }

  .flow-wrap {
    position: relative;
    overflow-x: clip;

    .schedule {
      position: relative;

      @include g.mq(md) {
        margin-top: 69px;
        padding-bottom: 289px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px 289px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(24);
        padding: 0 f.px2vw(40) f.px2vw(122);

      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-box {

          @include g.mq(md) {
            margin-top: 71px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(50);
          }

          .box {
            
            @include g.mq(md) {
              display: flex;
              justify-content: space-between;
              margin-top: 72px;
            }

            @include g.mq(sm) {
              margin-top: f.px2vw(38);
            }

            &:first-child {
              margin-top: 0;
            }

            &.box02 {
              .images {
                order: 0;

                @include g.mq(md) {
                  flex: 0 0 370px;
                  padding-left: 0;
                }
                .img {
                  @include g.mq(sm) {
                    margin-left: auto;
                  }
                }
              }

              .list {
                order: 1;
              }
            }

            .list {

              @include g.mq(md) {
                margin-top: 4px;
                flex: 0 1 100%;
              }

              .item {
                display: grid;

                @include g.mq(md) {
                  grid-template-columns: 205px auto;
                  margin-top: 40px;
                }
                @media screen and (min-width: 768px) and (max-width: 1020px) {
                  grid-template-columns: f.px2vw(205,1020) auto;
                }
                @include g.mq(sm) {
                  grid-template-columns: f.px2vw(97) auto;
                  margin-top: f.px2vw(25);
                }

                &:first-child {
                  margin-top: 0;
                }

                .date {
                  display: flex;

                  @include g.mq(md) {
                    flex: 0 0 205px;
                    grid-column: 1/2;
                    grid-row: 1/3;
                  }

                  @media screen and (min-width: 768px) and (max-width: 1020px) {
                    flex: 0 0 f.px2vw(205,1020);
                  }

                  @include g.mq(sm) {
                    grid-column: 1/2;
                    grid-row: 1/2;
                  }

                  .icon {

                    @include g.mq(md) {
                      flex: 0 0 35px;
                      margin-right: 13px;
                    }

                    @include g.mq(sm) {
                      flex: 0 0 f.px2vw(20);
                      margin-right: f.px2vw(5);
                    }
                  }

                  p {
                    flex: 0 1 100%;
                    font-weight: 500;
  
                    @include g.mq(md) {
                      font-size: 3rem;
                    }
                    @include g.mq(sm) {
                      font-size: f.px2vw(19);
                    }
                  }
                }
  
                .message {
                  display: flex;
                  align-items: center;

                  @include g.mq(md) {
                    margin-top: 6px;
                    grid-column: 2/3;
                    grid-row: 1/2;
                  }

                  @include g.mq(sm) {
                    grid-column: 2/3;
                    grid-row: 1/2;
                  }

                  .title {
                    font-weight: 200;

                    @include g.mq(md) {
                      font-size: 2.6rem;
                      margin-right: 10px;
                    }
                    @include g.mq(sm) {
                      font-size: f.px2vw(18);
                      margin-right: f.px2vw(5);
                    }
                  }

                  .category {
                    display: flex;

                    .cat-item {
                      font-weight: 500;
                      color: #fff;

                      @include g.mq(md) {
                        font-size: 14px;
                        padding: 5px 8px;
                        border-radius: 6px;
                      }
                      @include g.mq(sm) {
                        font-size: f.px2vw(12);
                        padding: f.px2vw(5) f.px2vw(8);
                        border-radius: f.px2vw(6);
                      }

                      &__01 {
                        background-color: #FF7700;

                        @include g.mq(md) {
                          margin-right: 5px;
                        }
                        @include g.mq(sm) {
                          margin-right: f.px2vw(5);
                        }
                      }

                      &__02 {
                        background-color: #00B2D1;
                      }
                    }
                  }
                }

                .txt {
                  text-align: left;
                  
                  @include g.mq(md) {
                    line-height: 1.5;
                    margin-top: 9px;
                    padding-right: 30px;
                    grid-column: 2/3;
                    grid-row: 2/3;
                  }
                  @include g.mq(sm) {
                    grid-column: 1/3;
                    grid-row: 2/3;
                    font-size: f.px2vw(13);
                  }
                }
              }
            }

            .images {

              @include g.mq(md) {
                flex: 0 0 413px;
                padding-left: 20px;
              }
              @include g.mq(sm) {
                max-width: f.px2vw(261);
                margin-left: auto;
                margin-top: f.px2vw(32);
              }

              .img {
                max-width: 300px;

                @include g.mq(md) {
                  margin-top: 58px;
                }
                @include g.mq(sm) {
                  max-width: f.px2vw(212);
                  margin-top: f.px2vw(18);
                }

                &:first-child {
                  margin-top: 0;
                }

                &-right {
                  margin-left: auto;
                }
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 5px;
              right: 176px;
              width: 128px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-53);
              width: f.px2vw(50);
              right: f.px2vw(5);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 967px;
              left: 173px;
              width: 72px;
            }
            @include g.mq(sm) {
              top: f.px2vw(1006);
              left: f.px2vw(40);
              width: f.px2vw(50);
            }
          }

          &-03 {

            @include g.mq(md) {
              top: 1210px;
              right: 208px;
              width: 58px;
            }
            @include g.mq(sm) {
              display: none;
            }
          }
        }
      }
    }

    .facility {
      position: relative;
      overflow-x: clip;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 64px 0 120px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 64px 20px 120px;
      }

      @include g.mq(sm) {
        padding: f.px2vw(37) f.px2vw(40) f.px2vw(120);
      }
  
      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-list {

          @include g.mq(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;  
            margin-top: 51px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(44);
          }

          .item {

            @include g.mq(md) {
              width: 30%;
              margin-bottom: 47px;
            }

            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(40);
            }

            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }

            .title {
              font-weight: 500;
              text-align: center;
              color: g.$col-blue;

              @include g.mq(md) {
                font-size: 2.4rem;
                margin-top: 18px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(22);
                margin-top: f.px2vw(13);
              }

              &.title02 {
                letter-spacing: -.05em;

                @include g.mq(md) {
                  font-size: 2.2rem;
                }
              }
            }

            .txt {
              text-align: left;

              @include g.mq(md) {
                line-height: 1.75;
                margin-top: 10px;
              }

              @include g.mq(sm) {
                line-height: 1.57;
                margin-top: f.px2vw(13);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -174px;
            right: 34px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: -2px;
              left: 354px;
              width: 64px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-99);
              left: f.px2vw(47);
              width: f.px2vw(57);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 30px;
              right: 335px;
              width: 44px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-46);
              right: f.px2vw(45);
              width: f.px2vw(36);
            }
          }
        }
      }
    }

    .event {
      position: relative;
      overflow-x: clip;

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(33);
        padding: 0 f.px2vw(40);
      }

      .content {
        max-width: 760px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-list {

          @include g.mq(md) {
            margin-top: 59px;
          }
          @include g.mq(sm) {
            position: relative;
            margin-top: f.px2vw(37);
          }

          .item {

            @include g.mq(md) {
              width: 100%;
              margin-bottom: 38px;
            }
            @include g.mq(sm) {
              margin-bottom: f.px2vw(50);
            }

            .title {
              border-bottom: 1px solid;

              @include g.mq(md) {
                padding-bottom: 6px;
              }
              @include g.mq(sm) {
                padding-bottom: f.px2vw(8);
              }

              .img {
                
                @include g.mq(md) {
                  width: 155px;
                }
                @include g.mq(sm) {
                  width: f.px2vw(136);
                }
              }
            }

            dl {
              display: flex;
              align-items: baseline;
              font-weight: 500;
              border-bottom: 1px dashed;

              @include g.mq(md) {
                font-size: 2rem;
                padding: 17px 0;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(20);
                padding: f.px2vw(20) 0;
              }

              dt {
                @include g.mq(md) {
                  flex: 0 0 55px;
                }
                @include g.mq(sm) {
                  flex: 0 0 f.px2vw(55);
                }
              }
              dd {
                flex: 0 1 100%;
                line-height: 1.5;
              }
            }

            .flex {
              @include g.mq(md) {
                display: flex;
              }

              .body-message {
                @include g.mq(md) {
                  flex: 0 1 100%;
                }
              }

              .body-img {

                @include g.mq(md) {
                  margin: 45px 0 0 38px;
                  flex: 0 0 320px;
                }
                @include g.mq(sm) {
                  margin-top: f.px2vw(22);
                }
              }
            }
            
            &.item01 {
              .title {
                border-color: #F078C7;
              }
              dl {
                border-color: #F078C7;
              }
              dt {
                color: #F078C7;
              }
            }
            &.item02{
              .title {
                border-color: #00D1A1;
              }
              dl {
                border-color: #00D1A1;
              }
              dt {
                color: #00D1A1;
              }
            }
            &.item03 {
              .title {
                border-color: #F4761B;
              }
              dl {
                border-color: #F4761B;
              }
              dt {
                color: #F4761B;
              }
            }
            &.item04 {
              margin-bottom: 0;
              .title {
                border-color: #00B2D1;
              }
              dl {
                border-color: #00B2D1;
              }
              dt {
                color: #00B2D1;
              }
            }
          }

          .content-box {
            background-color: g.$col-lightblue;
  
            @include g.mq(md) {
              display: flex;
              align-items: center;
              justify-content: center;  
              height: 91px;
              margin-top: 40px;
            }
            @include g.mq(sm) {
              padding: f.px2vw(10) f.px2vw(20);
              margin-top: f.px2vw(24);
            }

            .txt {
              text-align: left;
              text-indent: -1.5rem;
              padding-left: 1.5rem;

              @include g.mq(md) {
                line-height: 1.5;
                font-size: 1.6rem;
              }
              @include g.mq(sm) {
                line-height: 1.43;
                font-size: f.px2vw(14);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: 539px;
            left: 115px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon01.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: 217px;
            right: 136px;
            width: 226px;
            height: 226px;
          }

          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 23px;
              left: 372px;
              width: 50px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-63);
              left: f.px2vw(40);
              width: f.px2vw(42);
            }
          }

        }
      }
    }
  }
}