/* ==========================================================================
    variables
   ========================================================================== */

// font
$font-kiwi: 'Kiwi Maru', serif;

// color
$col-orange: #F4761B;
$col-blue: #00B2D1;
$col-lightblue: #F3F9F9;

$font-color: #000;


