@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
main
========================================================================== */
main {
  @include g.mq(md) {
    padding-top: 124px;
  }

  @include g.mq(sm) {
    padding-top: f.px2vw(70);
  }
}

/*# MV
==============================================================*/
.mv {

  .mv-wrap {
    position: relative;
    z-index: -2;

    @include g.mq(md) {
      padding: 0 60px;
    }

    @include g.mq(sm) {
      padding: 0 f.px2vw(20);
    }

    &__scroll {
      position: absolute;
      
      @include g.mq(md) {
        right: 30px;
        bottom: 32px;
      }

      @include g.mq(sm) {
        display: none;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        background-color: g.$col-blue;

        /*# animation
        ================*/
        animation: scroll-bar 1.5s ease infinite;

        @include g.mq(md) {
          left: 1px;
          bottom: 83px;
          width: 13px;
          height: 13px;  
        }
      }

      &::after {
        content: '';
        display: inline-block;
        background-color: g.$col-blue;

        @include g.mq(md) {
          width: 1px;
          height: 85px;
          margin-top: 12px;
          margin-left: 7px;  
        }
      }
    }
  }
}

/*# About
==============================================================*/
.about {
  position: relative;
  overflow: hidden;

  @include g.mq(md) {
    padding-bottom: 93px;
  }

  @include g.mq(sm) {
    padding-bottom: f.px2vw(132);
  }

  .about-wrap {
    position: relative;
    z-index: 2;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      padding: 0 20px;
    }

    @include g.mq(sm) {
      padding: 0 f.px2vw(20);
    }

    &__message {

      @include g.mq(md) {
        margin-top: 85px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(51);
      }

      h2 {

        @include g.mq(md) {
          font-size: 3.8rem;
          line-height: 1.47;
          padding-bottom: 34px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(28);
          line-height: 1.43;
          letter-spacing: -0.05em;
          padding-bottom: f.px2vw(50);
        }
      }

      p {
        text-align: center;

        @include g.mq(md) {
          line-height: 1.75;
          margin-top: 35px;
        }

        @include g.mq(sm) {
          line-height: 2;
          margin-top: f.px2vw(40);
        }
      }
    }

    &__list {

      @include g.mq(md) {
        display: flex;
        justify-content: space-between;  
        margin-top: 125px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(86);
        padding: 0px f.px2vw(20);
      }

      .list-item {

        @include g.mq(md) {
          width: 30.6%;
        }

        @include g.mq(sm) {
          margin-bottom: f.px2vw(38);
        }

        &__img {
          position: relative;

          .img-name {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            font-weight: 500;
            color: #fff;
            background-color: g.$col-blue;

            @include g.mq(md) {
              padding: 10px 23px;
              border-radius: 0 20px 0 0;
              font-size: 2.2rem;
            }

            @include g.mq(sm) {
              padding: f.px2vw(9) f.px2vw(23);
              border-radius: 0 f.px2vw(20) 0 0;
              font-size: f.px2vw(18);
            }
          }
        }

        &__message {
          background-color: g.$col-lightblue;

          @include g.mq(md) {
            padding: 20px 24px;
            border-radius: 0 0 14px 14px;
          }

          @include g.mq(sm) {
            padding: f.px2vw(11) f.px2vw(23);
            border-radius: 0 0 f.px2vw(14) f.px2vw(14);
          }

          p {

            @include g.mq(md) {
              line-height: 1.625;
            }

            @include g.mq(sm) {
              line-height: 1.57;
            }
          }
        }

        &:nth-child(2) {

          @include g.mq(md) {
            margin-top: 50px;
          }
        }

        &:last-child {

          @include g.mq(md) {
            margin-top: 100px;
          }

          @include g.mq(sm) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-bg {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;  

    @include g.mq(md) {
      width: 1480px;
      top: 85px;
    }

    @include g.mq(sm) {
      width: 100%;
      top: 0;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url("../img/common/bg-icon01.png");
      background-size: cover;
      background-repeat: no-repeat;
      
      @include g.mq(md) {
        top: 63px;
        left: 11px;
        width: 226px;
        height: 226px;
      }

      @include g.mq(sm) {
        top: f.px2vw(264);
        left: f.px2vw(-47);
        width: f.px2vw(112);
        height: f.px2vw(112);
      }
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url("../img/common/bg-icon02.png");
      background-size: cover;
      background-repeat: no-repeat;
      
      @include g.mq(md) {
        top: 196px;
        right: -51px;
        width: 271px;
        height: 249px;
      }

      @include g.mq(sm) {
        top: f.px2vw(705);
        right: f.px2vw(-27);    
        width: f.px2vw(151);
        height: f.px2vw(139);
      }
    }

    &__icon {
      position: absolute;


      &--01 {
        /*# animation
        ================*/
        animation:shake-icon01 2.5s steps(2,start) infinite;

        @include g.mq(md) {
          top: 5px;
          left: 270px;
          width: 64px;
        }
        @include g.mq(sm) {
          top: f.px2vw(21);
          left: f.px2vw(9);
          width: f.px2vw(45);
        }
      }
      &--02 {
        /*# animation
        ================*/
        animation:shake-icon02 2s steps(2,start) infinite;


        @include g.mq(md) {
          top: 48px;
          right: 206px;
          width: 98px;
        }
        @include g.mq(sm) {
          top: f.px2vw(182);
          right: f.px2vw(-5);
          width: f.px2vw(57);
        }
      }
      &--03 {
        /*# animation
        ================*/
        animation:shake-icon02 2.5s steps(2,start) infinite;


        @include g.mq(md) {
          top: 321px;
          left: 237px;
          width: 71px;
        }
        @include g.mq(sm) {
          top: f.px2vw(611);
          left: f.px2vw(15);
          width: f.px2vw(55);
        }
      }
      &--04 {
        /*# animation
        ================*/
        animation:shake-icon01 2s steps(2,start) infinite;

        @include g.mq(md) {
          top: 364px;
          right: 268px;
          width: 128px;
        }
        @include g.mq(sm) {
          top: f.px2vw(581);
          right: f.px2vw(9);
          width: f.px2vw(53);
        }
      }
    }
  }
}

/*# News
==============================================================*/
.news {
  overflow-x: clip;
  position: relative;

  .news-wrap {
    position: relative;
    background-color: #F3F9F9;

    @include g.mq(md) {
      padding: 64px 0 185px;
    }

    @media screen and (min-width: 768px) and (max-width: 1166px) {
      padding: 64px 20px 185px;
    }

    @include g.mq(sm) {
      padding-bottom: f.px2vw(121);
    }

    .splide {
      position: relative;
      z-index: 10;
      max-width: 1146px;
      margin: 0 auto;

      @include g.mq(md) {
        margin-top: 37px;
        padding: 0 73px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(34);
      }

      .splide__arrows {

        .splide__arrow {
          position: absolute;
          cursor: pointer;
          z-index: 10;

          @include g.mq(md) {
            top: 59px;
            width: 43px;
          }

          @include g.mq(sm) {
            top: f.px2vw(62);
            width: f.px2vw(33);
          }

          &--prev {
            left: 0;

            @include g.mq(sm) {
              left: f.px2vw(10);
            }

            img {
              transform: rotate(180deg);
            }

            &:disabled {
              opacity: .5;
            }
          }

          &--next {
            right: 0;

            @include g.mq(sm) {
              right: f.px2vw(10);
            }

            &:disabled {
              pointer-events: none;
              opacity: .5;
            }
          }
        }
      }

      .splide__track  {
        max-width: 1000px;
        margin: 0 auto;
      }
    }

    &__list {

      .list-item {

        @include g.mq(md) {
          width: 23.4%;
        }

        @include g.mq(sm) {
          width: f.px2vw(234);
        }

        &__message {

          .date {
            display: flex;
            align-items: center;

            @include g.mq(md) {
              font-size: 1.3rem;
              margin-top: 15px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(13);
              margin-top: f.px2vw(15);
            }

            span {
              background-color: g.$col-orange;
              color: #fff;

              @include g.mq(md) {
                font-size: 1.2rem;
                padding: 4px 10px;
                margin-left: 12px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(12);
                letter-spacing: 0.049;
                padding: f.px2vw(4) f.px2vw(11);
                margin-left: f.px2vw(15);
              }
            }
          }

          .txt {

            @include g.mq(md) {
              font-size: 1.6rem;
              margin-top: 8px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(16);
              margin-top: f.px2vw(8);
            }
          }
        }

        &__img {
          height: 158px;

          @include g.mq(sm) {
            height: f.px2vw(158);
          }
          img {
            height: 100%;
            object-fit: cover;

            @include g.mq(md) {
              border-radius: 10px;
            }
            @include g.mq(sm) {
              border-radius: f.px2vw(10);
            }
          }
        }
      }
    }

    .more-link {
      max-width: 1000px;
      margin: 0 auto;
      text-align: right;

      @include g.mq(md) {
        margin-top: 27px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(7);
        padding: 0 f.px2vw(40);
      }

      a {
        position: relative;
        z-index: 10;
        display: inline-block;
        border-bottom: 1px solid #000;

        @include g.mq(md) {
          font-size: 1.6rem;
          padding: 0 17px 3px 0;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(16);
          padding: 0 f.px2vw(16) f.px2vw(3) 0;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent #000000;

          @include g.mq(md) {
            border-width: 5px 0 5px 8px;
          }

          @include g.mq(sm) {
            border-width: f.px2vw(5) 0 f.px2vw(5) f.px2vw(8);
          }
        }
      }
    }
  }

  .news-bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    @include g.mq(md) {
      top: 85px;
      width: 1480px;
    }

    @include g.mq(sm) {
      top: 0;
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      background-image: url("../img/common/bg-icon01.png");
      background-size: cover;
      background-repeat: no-repeat;
      
      @include g.mq(md) {
        bottom: 42px;
        right: 0;
        width: 226px;
        height: 226px;
      }
      @include g.mq(sm) {
        bottom: f.px2vw(-30);
        right: 0;
        width: f.px2vw(125);
        height: f.px2vw(125);
      }
    }

    &__icon {
      position: absolute;

      &--01 {
        /*# animation
        ================*/
        animation:shake-icon01 2.5s steps(2,start) infinite;

        @include g.mq(md) {
          top: -93px;
          right: 214px;
          width: 128px;
        }
        @include g.mq(sm) {
          top: f.px2vw(-58);
          right: f.px2vw(20);
          width: f.px2vw(59);
        }
      }
      &--02 {
        /*# animation
        ================*/
        animation:shake-icon02 2s steps(2,start) infinite;

        @include g.mq(md) {
          bottom: 141px;
          left: 173px;
          width: 67px;
        }
        @include g.mq(sm) {
          bottom: f.px2vw(32);
          left: f.px2vw(29);
          width: f.px2vw(39);
        }
      }
    }
  }

}

/*# Recruit
==============================================================*/
.recruit {

  @include g.mq(md) {
    margin-bottom: 126px;
  }

  @media screen and (min-width: 768px) and (max-width: 1020px) {
    padding: 0 20px;
  }

  @include g.mq(sm) {
    padding: 0 f.px2vw(20);
    margin-bottom: f.px2vw(100);
  }

  .recruit-wrap {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #FAF7ED;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;  
      border-radius: 20px;
      padding-left: 73px;
    }

    @media screen and (min-width: 768px) and (max-width: 1020px) {
      padding-left: f.px2vw(73,1020);
    }

    @include g.mq(sm) {
      border-radius: f.px2vw(20) f.px2vw(20) 0 0;
    }

    &__message {

      @include g.mq(md) {
        flex: 0 1 100%;
        padding: 10px 0;
      }

      @include g.mq(sm) {
        padding: f.px2vw(45) f.px2vw(20) 0;
      }

      h2 {
        font-weight: 500;

        @include g.mq(md) {
          font-size: 3rem;
        }

        @media screen and (min-width: 768px) and (max-width: 1020px) {
          font-size: f.px2vw(30,1020);
        }

        @include g.mq(sm) {
          font-size: f.px2vw(20);
          letter-spacing: 0.05em;
          text-align: center;
        }
      }

      p {
        font-weight: 300;

        @include g.mq(md) {
          font-size: 1.6rem;
          line-height: 1.75;
          margin-top: 17px;
        }

        @media screen and (min-width: 768px) and (max-width: 1020px) {
          font-size: f.px2vw(16,1020);
          margin-top: f.px2vw(17,1020);
        }

        @include g.mq(sm) {
          font-size: f.px2vw(13);
          line-height: 1.69;
          text-align: center;
          margin-top: f.px2vw(7);
        }
      }

      .button-link {

        @include g.mq(md) {
          margin-top: 19px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(16);
          text-align: center;
        }

        a {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: g.$col-orange;
          background-color: #fff;

          @include g.mq(md) {
            width: 218px;
            height: 40px;
            border-radius: 41px;
            font-size: 1.6rem;
          }

          @include g.mq(sm) {
            width: f.px2vw(211);
            height: f.px2vw(36);
            border-radius: f.px2vw(41);
            font-size: f.px2vw(16);
            letter-spacing: -0.05em;
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-style: solid;
            border-color: transparent transparent transparent g.$col-orange;

            @include g.mq(md) {
              right: 20px;
              border-width: 5px 0 5px 8px;
            }

            @include g.mq(sm) {
              right: f.px2vw(18);
              border-width: f.px2vw(5) 0 f.px2vw(5) f.px2vw(8);
            }
          }
        }
      }
    }

    &__img {

      @include g.mq(md) {
        flex: 0 0 446px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        flex: 0 0 f.px2vw(446,1020);
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(32);
      }
    }
  }
}