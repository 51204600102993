@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# 園の特色
==============================================================*/
#features {

  @include g.mq(md) {
    margin-bottom: 135px;
  }
  @include g.mq(sm) {
    margin-bottom: f.px2vw(141);
  }

  .features-wrap {

    .list {
      position: relative;
      overflow: hidden;

      @include g.mq(md) {
        margin-top: 59px;
        padding-bottom: 96px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px 96px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(11);
        padding-bottom: f.px2vw(113);
      }

      .content {
        position: relative;
        z-index: 2;
        max-width: 1000px;
        margin: 0 auto;

        @include g.mq(sm) {
          padding: 0 f.px2vw(40);
        }

        .content-list {

          @include g.mq(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;  
            margin-top: 51px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(38);
          }

          .item {

            @include g.mq(md) {
              width: 30%;
              margin-bottom: 47px;
            }

            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(40);
            }

            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }

            .title {
              font-weight: 500;
              text-align: center;
              color: g.$col-blue;

              @include g.mq(md) {
                font-size: 2.4rem;
                margin-top: 18px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(22);
                margin-top: f.px2vw(13);
              }

              &.title02 {
                letter-spacing: -.05em;

                @include g.mq(md) {
                  font-size: 2.2rem;
                }
              }
            }

            .txt {
              text-align: left;

              @include g.mq(md) {
                line-height: 1.75;
                margin-top: 10px;
              }

              @include g.mq(sm) {
                line-height: 1.57;
                margin-top: f.px2vw(13);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
          top: 0;
        }

        @include g.mq(sm) {
          display: none;
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 200px;
              right: 68px;
              width: 98px;
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 588px;
              left: 48px;
              width: 59px;
            }
          }
        }
      }
    }

    .anzen {
      position: relative;
      overflow-x: clip;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 64px 0 166px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 64px 20px 166px;
      }

      @include g.mq(sm) {
        padding: f.px2vw(37) f.px2vw(40) f.px2vw(120);
      }

      .content {
        position: relative;
        max-width: 1000px;
        margin: 0 auto;
        z-index: 2;

        .content-txt {
          
          @include g.mq(md) {
            margin-top: 37px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(31);
          }
        }

        .content-list {

          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(37);
          }

          .item {
            @include g.mq(md) {
              width: 48.5%;
            }
            @include g.mq(sm) {
              margin-top: f.px2vw(40);
            }
            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }
            .title {
              font-weight: 500;
              text-align: center;
              color: g.$col-blue;

              @include g.mq(md) {
                font-size: 2.4rem;
                line-height: 1.25;
                margin-top: 23px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(20);
                line-height: 1.3;
                margin-top: f.px2vw(13);
              }
            }

            .txt {
              text-align: left;

              @include g.mq(md) {
                line-height: 1.75;
                margin-top: 17px;
              }

              @include g.mq(sm) {
                line-height: 1.57;
                margin-top: f.px2vw(11);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -160px;
            right: 34px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 13px;
              left: 353px;
              width: 64px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-65);
              left: f.px2vw(47);
              width: f.px2vw(57);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 91px;
              right: 253px;
              width: 128px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-60);
              right: f.px2vw(20);
              width: f.px2vw(84);
            }
          }
        }
      }
    }

    .lunch {
      position: relative;
      overflow-x: clip;

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(23);
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        @include g.mq(sm) {
          padding: 0 f.px2vw(40);
        }

        .content-txt {
          
          @include g.mq(md) {
            margin-top: 35px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(26);
          }
        }

        .content-list {
          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            margin-top: 44px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(20);
          }

          .item {
            @include g.mq(md) {
              width: 31%;
            }
            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(38);
            }
            &:first-child {
              @include g.mq(sm) {
                margin-top: 0;
              }
            }
            .title {
              font-weight: 500;
              text-align: center;
              color: g.$col-blue;

              @include g.mq(md) {
                font-size: 2.4rem;
                line-height: 1.25;
                margin-top: 10px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(20);
                line-height: 1.3;
                margin-top: f.px2vw(11);
              }
            }

            .txt {
              @include g.mq(md) {
                line-height: 1.75;
                margin-top: 7px;
              }
              @include g.mq(sm) {
                line-height: 1.57;
                margin-top: f.px2vw(11);
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon01.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -110px;
            left: 4px;
            width: 226px;
            height: 226px;
          }

          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: -48px;
              left: 410px;
              width: 44px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-73);
              left: f.px2vw(40);
              width: f.px2vw(35);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 39px;
              right: 365px;
              width: 50px;
            }
            @include g.mq(sm) {
              top: f.px2vw(5);
              right: f.px2vw(20);
              width: f.px2vw(40);
            }
          }
        }
      }
    }
  }
}