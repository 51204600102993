@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# ⼊園案内
==============================================================*/
#guidance {

  .guidance-wrap {

    .recruiting {
      position: relative;
      overflow-x: clip;

      @include g.mq(md) {
        margin-top: 70px;
        padding-bottom: 178px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 0 20px 178px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(20);
        padding: 0 f.px2vw(20) f.px2vw(133);
      }

      .content {
        max-width: 1000px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-box {
          
          @include g.mq(md) {
            display: flex;
            margin-top: 89px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(48);
          }

          .list {

            @include g.mq(md) {
              flex: 0 1 100%;
            }
            @include g.mq(sm) {
              padding: 0 f.px2vw(20);
            }

            .dl-list {

              dl {
                display: flex;
                align-items: baseline;
                border-bottom: 1px solid g.$col-blue;
                font-weight: 200;
  
                @include g.mq(md) {
                  font-size: 1.6rem;
                  padding: 12px 0;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(14);
                  padding: f.px2vw(14) 0;
                }
  
                &:first-child {
                  padding-top: 0;
                }
  
                &:last-child {
                  border-bottom: none;
                }
  
                dt {
                  color: g.$col-blue;
  
                  @include g.mq(md) {
                    flex: 0 0 120px;
                  }
                  @include g.mq(sm) {
                    flex: 0 0 f.px2vw(94);
                  }
                }
  
                dd {
                  flex: 0 1 100%;
  
                  @include g.mq(md) {
                    line-height: 1.75;
                  }
                  @include g.mq(sm) {
                    line-height: 1.57;
                    padding-right: f.px2vw(10);
                  }
  
                  .indent {
                    text-indent: -1.7rem;
                    padding-left: 1.7rem;
                  }
                }
              }
            }

            .txt {
              font-weight: 200;

              @include g.mq(md) {
                font-size: 1.4rem;
                line-height: 1.43;
                margin-top: 20px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(14);
                line-height: 1.29;
                margin-top: f.px2vw(9);
              }
            }
          }

          .img {

            @include g.mq(md) {
              flex: 0 0 433px;
              margin-left: 30px;
            }

            @media screen and (min-width: 768px) and (max-width: 1020px) {
              flex: 0 0 f.px2vw(433,1020);
            }

            @include g.mq(sm) {
              margin-top: f.px2vw(51);
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          display: none;
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 178px;
              right: 68px;
              width: 98px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-53);
              width: f.px2vw(50);
              right: f.px2vw(5);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 366px;
              left: 48px;
              width: 58px;
            }
            @include g.mq(sm) {
              top: f.px2vw(1006);
              left: f.px2vw(40);
              width: f.px2vw(50);
            }
          }

          &-03 {

            @include g.mq(md) {
              top: 680px;
              left: -40px;
              width: 72px;
            }
            @include g.mq(sm) {
              display: none;
            }
          }
        }
      }
    }

    .flow {
      position: relative;
      overflow-x: clip;
      background-color: g.$col-lightblue;

      @include g.mq(md) {
        padding: 64px 0 173px;
      }

      @media screen and (min-width: 768px) and (max-width: 1020px) {
        padding: 64px 20px 120px;
      }

      @include g.mq(sm) {
        padding: f.px2vw(37) f.px2vw(20) f.px2vw(130);
      }
  
      .content {
        max-width: 880px;
        margin: 0 auto;
        position: relative;
        z-index: 2;

        .content-box {

          @include g.mq(md) {
            margin-top: 70px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(41);
          }

          .item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #fff;

            @include g.mq(md) {
              padding: 25px 50px 25px 145px;
              margin-top: 65px;
              border-radius: 15px;
              box-shadow: 5px 5px 0 rgba(0,178,209,.5);
            }
            @include g.mq(sm) {
              padding: f.px2vw(13) 0 f.px2vw(20);
              border-radius: f.px2vw(15);
              box-shadow: f.px2vw(5) f.px2vw(5) 0 rgba(0,178,209,.5);
              margin-top: f.px2vw(61);
            }

            &::after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-style: solid;              
              border-color: rgba(0,178,209,.5) transparent transparent transparent;

              @include g.mq(md) {
                bottom: -45px;
                border-width: 25px 58.5px 0 58.5px;
              }
              @include g.mq(sm) {
                bottom: f.px2vw(-40);
                border-width: f.px2vw(20) f.px2vw(46.5) 0 f.px2vw(46.5);
              }
            }

            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }

            .number {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              color: #fff;

              @include g.mq(md) {
                width: 98px;
                height: 57px;
                border-radius: 15px 0 15px 0;
                font-size: 3rem;
              }
              @include g.mq(sm) {
                width: f.px2vw(71);
                height: f.px2vw(41);
                border-radius: f.px2vw(15) 0 f.px2vw(15) 0;
                font-size: f.px2vw(24);
              }

              &.number01 {
                background-color: #F4761B;
              }
              &.number02 {
                background-color: #00D1A1;
              }
              &.number03 {
                background-color: #00B2D1;
              }
              &.number04 {
                background-color: #F078C7;
              }

              span {

                @include g.mq(md) {
                  font-size: 2rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(16);
                }
              }
            }

            .title {
              font-weight: 500;
              color: g.$col-blue;

              @include g.mq(md) {
                font-size: 3rem;
                letter-spacing: 0.2em;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(22);
                line-height: 1.27;
                letter-spacing: 0.1em;
                padding-left: f.px2vw(91);
              }
            }

            .txt {
              line-height: 1.57;
              text-align: left;

              @include g.mq(md) {
                font-size: 1.4rem;
                margin-top: 12px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(14);
                margin-top: f.px2vw(18);
                padding: 0 f.px2vw(14) 0 f.px2vw(20); 
              }
            }
          }
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    
        @include g.mq(md) {
          width: 1480px;
          height: 100%;  
        }
        @include g.mq(sm) {
          width: 100%;
          height: 100%;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon01.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: 674px;
            left: 3px;
            width: 226px;
            height: 226px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          background-image: url("../img/common/bg-icon02.png");
          background-size: cover;
          background-repeat: no-repeat;
          
          @include g.mq(md) {
            top: -174px;
            right: 34px;
            width: 271px;
            height: 249px;
          }
    
          @include g.mq(sm) {
            display: none;
          }
        }

        .icon {
          position: absolute;

          &-01 {

            @include g.mq(md) {
              top: 13px;
              left: 353px;
              width: 64px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-69);
              left: f.px2vw(37);
              width: f.px2vw(57);
            }
          }

          &-02 {

            @include g.mq(md) {
              top: 24px;
              right: 254px;
              width: 128px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-64);
              right: f.px2vw(30);
              width: f.px2vw(84);
            }
          }
        }
      }
    }
  }
}