// global/_mixins.scss
@use "variables" as var;

/* ==========================================================================
  mixin
  ========================================================================== */
// break point
// デフォルトはmdでレイアウト切り替え
$breakpoints: (
  'sm': (max-width: 767px),
  'md': (min-width: 768px),
  'tab1020' : (min-width: 767px) and (max-width: 1020px),
  'tab_sm': (max-width: 1300px),
  'tab_md': (min-width: 1301px),
) !default;

/* --------------------------------------------------------------------------
  media query (SP（小）→ PC（大）の順に適用)
  -------------------------------------------------------------------------- */
@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
}