@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# お問い合わせ
==============================================================*/
#contact {

  .input {
    
    .input-wrap {
      max-width: 880px;
      margin: 0 auto;

      @include g.mq(md) {
        margin-top: 84px;
        margin-bottom: 100px;
      }
  
      @include g.mq(sm) {
        margin-top: f.px2vw(23);
        margin-bottom: f.px2vw(142);
      }

      &__txt {
        font-weight: 200;
        text-align: center;

        @include g.mq(md) {
          font-size: 1.6rem;
          line-height: 1.75;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(14);
          line-height: 1.7;
        }
      }

      &__form {

        @include g.mq(md) {
          margin-top: 87px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(52);
        }

        .content {

          @include g.mq(sm) {
            padding: 0 40px;
          }

            .box {

              @include g.mq(md) {
                padding-bottom: 67px;
              }
              @include g.mq(sm) {
                padding-bottom: f.px2vw(40);
              }

              &:last-child {
                padding-bottom: 0;
              }

              .title {
                font-weight: 500;

                @include g.mq(md) {
                  font-size: 1.8rem;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(16);
                }

                span {
                  color: #fff;

                  @include g.mq(md) {
                    font-size: 1.4rem;
                    border-radius: 4px;
                    padding: 0 10px;
                    margin-right: 12px;
                  }
                  @include g.mq(sm) {
                    font-size: f.px2vw(12);
                    border-radius: f.px2vw(4);
                    padding: f.px2vw(2) f.px2vw(10);
                    margin-right: f.px2vw(8);
                  }

                  &.must {
                    background-color: g.$col-orange;
                  }

                  &.any {
                    background-color: #BEBEBE;
                  }
                }
              }
  
              .input-box {

                @include g.mq(sm) {
                  margin-top: f.px2vw(23);
                }


                input {
                  width: 100%;
                  border: 1px solid #E0E0E0;

                  @include g.mq(md) {
                    height: 62px;
                    padding: 18px 40px;
                    margin-top: 14px;
                    font-size: 1.6rem;
                  }
                  @include g.mq(sm) {
                    height: f.px2vw(57);
                    padding: f.px2vw(16) f.px2vw(18);
                    font-size: f.px2vw(16);
                  }

                  &::placeholder {
                    color: #A3A3A3;
                  }
                }

                textarea {
                  width: 100%;
                  border: 1px solid #E0E0E0;

                  @include g.mq(md) {
                    height: 295px;
                    padding: 18px 40px;
                    margin-top: 14px;
                  }

                  @include g.mq(sm) {
                    height: f.px2vw(295);
                    padding: f.px2vw(16) f.px2vw(18);
                    font-size: f.px2vw(16);
                    line-height: 1.75;
                  }

                  &::placeholder {
                    color: #A3A3A3;
                  }
                }

                .radio-box {
                  display: inline-block;

                  @include g.mq(md) {
                    margin-right: 30px;
                    padding-top: 37px;
                    font-size: 1.6rem;
                  }

                  input[type=radio] {
                    display: none;
                  }
                  .radio {
                    box-sizing: border-box;
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                    width: auto;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    @include g.mq(md) {
                      font-size: 1.6rem;
                      padding-left: 30px;
                    }

                    @include g.mq(sm) {
                      font-size: f.px2vw(14);
                      margin-bottom: f.px2vw(18);
                      padding-left: f.px2vw(26);
                      margin-right: f.px2vw(18);
                    }
                  }
                  .radio::before {
                      background: #fff;
                      border: 1px solid #000;
                      border-radius: 50%;
                      content: '';
                      display: block;
                      left: 0;
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);

                      @include g.mq(md) {
                        width: 20px;
                        height: 20px;  
                      }
                      @include g.mq(sm) {
                        width: f.px2vw(20);
                        height: f.px2vw(20);  
                      }
                  }
                  .radio::after {
                    background: #000;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    @include g.mq(md) {
                      left: 6px;
                      width: 8px;
                      height: 8px;  
                    }
                    @include g.mq(sm) {
                      left: f.px2vw(6);
                      width: f.px2vw(8);
                      height: f.px2vw(8);  
                    }
                  }
                  input[type=radio]:checked + .radio::before {
                    border: 1px solid #000;
                  }
                  input[type=radio]:checked + .radio::after {
                    opacity: 1;
                  }
                  
                }

                .error {
                  @include g.mq(md) {
                    margin-top: 10px;
                  }
                  @include g.mq(sm) {
                    margin-top: f.px2vw(10);
                  }
                }
              }
            }

            .checkbox {

              @include g.mq(md) {
                font-size: 1.6rem;
                line-height: 1.75;
              }
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
                font-size: f.px2vw(12);
                line-height: 1.5;
              }

              a {
                text-decoration: underline;
              }

              .input-box {
                text-align: center;

                @include g.mq(md) {
                  margin-top: 15px;
                }
                @include g.mq(sm) {
                  margin-top: f.px2vw(39);
                }

                input[type=checkbox] {
                  display: none;
                }
                .checkbox {
                  cursor: pointer;
                  display: inline-flex;
                  align-items: center;
                  position: relative;
                  width: auto;

                  @include g.mq(md) {
                    padding-left: 28px;
                  }
                  @include g.mq(sm) {
                    padding-left: f.px2vw(28);
                  }
                  
                }
                .checkbox::before {
                  background: #fff;
                  border: 1px solid #B9B9B9;
                  content: '';
                  display: block;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);

                  @include g.mq(md) {
                    left: -7px;
                    width: 18px;
                    height: 18px;
                  }

                  @include g.mq(sm) {
                    left: 1px;
                    width: f.px2vw(18);
                    height: f.px2vw(18);
                  }
                }
                .checkbox::after {
                  background: url("../img/common/check.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                  content: '';
                  display: block;
                  opacity: 0;
                  position: absolute;
                  top: 51%;
                  transform: translateY(-50%);

                  @include g.mq(md) {
                    width: 12px;
                    height: 9px;
                    left: -4px;
                  }

                  @include g.mq(sm) {
                    left: f.px2vw(3);
                    width: f.px2vw(12);
                    height: f.px2vw(9);
                  }
                }
                input[type=checkbox]:checked + .checkbox::after {
                  opacity: 1;
                }
              }
            }
        }

        .button {
          position: relative;
          max-width: 300px;
          margin: 0 auto;

          @include g.mq(md) {
            height: 60px;
            margin-top: 45px;
          }
          @include g.mq(sm) {
            max-width: f.px2vw(227);
            height: f.px2vw(49);
            margin-top: f.px2vw(50);
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent transparent transparent #fff;

            @include g.mq(md) {
              right: 20px;
              border-width: 5px 0 5px 8px;
            }
            @include g.mq(sm) {
              right: f.px2vw(20);
              border-width: f.px2vw(5) 0 f.px2vw(5) f.px2vw(8);
            }
          }

          input {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-weight: 500;
            background-color: g.$col-blue;
            color: #fff;

            @include g.mq(md) {
              border-radius: 41px;
              font-size: 1.6rem;
            }
            @include g.mq(sm) {
              font-size: f.px2vw(16);
              border-radius: f.px2vw(25);
            }
          }
        }
      }
    }
  }
}

/*===========================================
# サンクスページ
/*===========================================*/
#finish {

  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 200px 20px;

    @include g.mq(md) {
      padding: 100px 20px 200px;
    }

    @include g.mq(sm) {
      padding: 50px 10px 100px;
    }

    p {
      font-size: 18px;
      text-align: center;
      line-height: 1.5;

      @include g.mq(sm) {
        font-size: 16px;
      }
    }

    ul {
      text-align: center;

      li {
        position: relative;
        max-width: 300px;
        margin: 0 auto;

        @include g.mq(md) {
          height: 60px;
          margin-top: 45px;
        }
        @include g.mq(sm) {
          max-width: f.px2vw(227);
          height: f.px2vw(49);
          margin-top: f.px2vw(25);
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent #fff;

          @include g.mq(md) {
            right: 20px;
            border-width: 5px 0 5px 8px;
          }
          @include g.mq(sm) {
            right: f.px2vw(20);
            border-width: f.px2vw(5) 0 f.px2vw(5) f.px2vw(8);
          }
        }

        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          background-color: g.$col-blue;
          color: #fff;

          @include g.mq(md) {
            border-radius: 41px;
            font-size: 1.6rem;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(13);
            border-radius: f.px2vw(25);
          }
        }
      }
    }
  }
}